import React from 'react';

function Login() {
  return (
    <div>
      <h2>Login</h2>
      <p>Please log in to access your account.</p>
    </div>
  );
}

export default Login;
